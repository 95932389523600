<template>
    <div class="login-container d-flex align-items-center">
        <div class="form-login">
        <form @submit.prevent="validateFormAndRegister()">
            <small class="text-muted size18 fw-normal">Welcome</small>
            <h3 class="fw-bold mb-4 text-black">Register</h3>
            <div class="mb-3 textBox-width">
                <span class="p-float-label">
                    <InputText
                        id="name"
                        type="text"
                        class="p-inputtext w-100"
                        v-model="v$.name.$model"
                    />
                    <label for="name">Name</label>
                </span>
                <span
                    v-if="v$.name.$error && (v$.name.required && v$.name.required.$invalid)"
                    class="text-danger">{{messages.registrationValidation.nameRequired}}
                </span>
            </div>
            <div class="mb-3 textBox-width">
                <span class="p-float-label">
                <InputText
                    id="email"
                    type="text"
                    class="p-inputtext w-100"
                    @input="onEmailInput"
                    v-model="v$.email.$model"
                />
                <label for="email">Email</label>
                </span>
                <span
                    v-if="v$.email.$error && (v$.email.required && v$.email.required.$invalid)"
                    class="text-danger">{{messages.registrationValidation.emailRequired}}
                </span>
                <span
                    v-else-if="v$.email.$error && (v$.email.email && v$.email.email.$invalid)"
                    class="text-danger">{{messages.registrationValidation.emailInvalid}}
                </span>
            </div>
            <div class="mb-3 textBox-width">
                <span class="p-float-label">
                <InputText
                    id="password"
                    type="password"
                    class="p-inputtext w-100"
                    v-model="v$.password.$model"
                />
                <label for="password">Password</label>
                </span>
                <span
                    v-if="v$.password.$error && (v$.password.passwordRequired && v$.password.passwordRequired.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordRequired}}
                </span>
                <span
                    v-else-if="v$.password.$error && (v$.password.passwordMustHaveFormat && v$.password.passwordMustHaveFormat.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordCondition}}
                </span>
            </div>
            <div class="mb-4 textBox-width">
                <span class="p-float-label">
                <InputText
                    id="confirmpassword"
                    type="password"
                    class="p-inputtext w-100"
                    v-model="v$.confirmPassword.$model"
                />
                <label for="confirmpassword">Confirm Password</label>
                </span>
                <span
                    v-if="v$.confirmPassword.$error && (v$.confirmPassword.sameAsPassword && v$.confirmPassword.sameAsPassword.$invalid)"
                    class="text-danger">{{messages.registrationValidation.confirmPassword}}
                </span>
            </div>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
            <Button
                class="lavender-purple-background justify-content-center textBox-width mt-2"
                type="submit"
                :disabled="loading"
                ><span class="size18 fw-normal">REGISTER</span></Button
            >
            <Button class="p-button-outlined justify-content-center textBox-width mt-3" type="submit" @click.prevent="cancel()"
                ><span class="size18 fw-normal">CANCEL</span></Button
            >
        </form>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import { passwordMustHaveFormat, passwordRequired } from '../utils/validation-utils';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { UserRole, Status } from '../enums';
import { AuthService, UserService } from '../../apis';
import { serverTimeStamp } from '../../../firebase';
import { messages, userActivities } from '../constants/index';
import { UserActivity } from '../utils/UserActivity';

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'Register',
    components: {
        InputText,
        Button
    },
    data() {
        return {
            email: '',
            name: '',
            password: '',
            confirmPassword: '',
            errorMessage: '',
            loading: false,
            messages
        };
    },
    validations() {
        return {
            email: { required, email },
            name: { required },
            password: { passwordRequired, passwordMustHaveFormat },
            confirmPassword: { sameAsPassword: sameAs(this.password) }
        };
    },
    methods: {
        async validateFormAndRegister() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            this.email = this.email.trim().toLowerCase();
            this.name = this.name.trim();
            this.loading = true;
            AuthService.register(this.email, this.password).then(
                user => {
                    const userId = user.user.uid;
                    UserService.update(userId, {
                        added: serverTimeStamp(),
                        name: this.name.trim(),
                        email: user.user.email,
                        firebaseId: userId,
                        role: UserRole.User,
                        state: Status.Active,
                        logins: [],
                        info: {
                            ...UserActivity.getActivity(userActivities.created, true, userId),
                            ...UserActivity.getActivity(userActivities.updated, true, userId)
                        }
                    })
                        .then(async () => {
                            await AuthService.logout();
                            this.$router.replace({ name: 'Login' });
                        })
                        .finally(() => (this.loading = false));
                },
                err => {
                    this.loading = false;
                    switch (err.code) {
                    case 'auth/email-already-in-use':
                        this.errorMessage = messages.registrationValidation.emailExist;
                        break;
                    default:
                        this.errorMessage = messages.registrationValidation.errorOccurred;
                        break;
                    }
                }
            );
        },
        onEmailInput(e) {
            this.email = e.target.value = e.target.value.trim().toLowerCase();
        },
        cancel() {
            this.$router.replace({ path: '/' });
        }
    }
};
</script>

<style scoped></style>
